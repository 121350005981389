<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <tiptap-vuetify
      :value="value"
      :placeholder="field.label"
      @input="onChange"
      :extensions="extensions"
    ></tiptap-vuetify>
    <span v-if="errors.length > 0" class="error--text text-caption">{{
      errors[0]
    }}</span>
  </validation-provider>
</template>

<script>
import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  HardBreak,
} from "tiptap-vuetify";
export default {
  components: {
    TiptapVuetify,
  },
  props: ["field", "value", "validations"],
  data() {
    return {
      extensions: [Bold, Italic, Underline, HardBreak],
    };
  },
  methods: {
    onChange: function (event) {
      this.$emit("fieldChange", event);
    },
  },
};
</script>
<style>
.ProseMirror {
  min-height: 20px;
}
</style>

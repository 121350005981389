<template>
  <v-dialog v-model="dialog" persistent width="600">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="newStub"
        small
        rounded
        dark
        v-on="on"
        @click="dialog = true"
        color="primary"
        ><v-icon small>mdi-plus</v-icon>New Stub</v-btn
      >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            v-if="!newStub"
            small
            class="mr-2"
            v-on="on"
            @click="dialog = true"
            >mdi-pencil</v-icon
          >
        </template>
        <span>Edit Stub</span>
      </v-tooltip>
    </template>

    <v-card v-if="dialog">
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-card-title> Narrative Stub </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-6">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-autocomplete
              label="Question"
              v-model="question"
              :items="questionsList"
              :item-text="(item) => `${item.sequence}. ${item.label}`"
              return-object
              item-value="questionId"
              :error-messages="errors"
            >
              <template v-slot:selection="{ item }">
                <span
                  v-sanitize-html="`${item.sequence}. ${item.label}`"
                ></span>
              </template>
              <template v-slot:item="{ item }">
                <span
                  v-sanitize-html="`${item.sequence}. ${item.label}`"
                ></span>
              </template>
            </v-autocomplete>
          </validation-provider>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              label="Stub Name"
              v-model="stubRep"
              :error-messages="errors"
            ></v-text-field
          ></validation-provider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="clear"> Cancel </v-btn>
          <v-btn color="primary" text :disabled="invalid" @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "../../plugins/vee-validate/validation";

export default {
  props: ["newStub", "stub"],
  data() {
    return {
      dialog: false,
      stubRep: this.stub?.representation || "",
      question: this.stub?.question || "",
    };
  },
  computed: {
    questions: function () {
      return this.$store.state.templatesModule.questions.sort(
        (a, b) =>
          a.family?.sequence - b.family?.sequence || a.sequence - b.sequence
      );
    },
    questionsList: function () {
      return this.questions.reduce((group, question) => {
        let match = group.find((item) => item.familyId === question.familyId);
        if (!match) {
          group.push({ header: question.family?.label });
        }
        group.push(question);

        return group;
      }, []);
    },
    templateId: function () {
      return this.$store.getters["templatesModule/getSelectedTemplateId"];
    },
  },
  watch: {
    stubRep: function (value) {
      this.stubRep = value.toUpperCase().replace(/\s+/g, "_");
    },
  },
  methods: {
    save: async function () {
      let message = false;
      if (this.newStub) {
        message = await this.$store.dispatch("templatesModule/addStub", {
          templateId: this.templateId,
          questionId: this.question.questionId,
          question: this.question,
          representation: this.stubRep,
        });
      } else {
        message = await this.$store.dispatch("templatesModule/updateStub", {
          narrativeStubId: this.stub.narrativeStubId,
          templateId: this.templateId,
          questionId: this.question.questionId,
          question: this.question,
          representation: this.stubRep,
        });
      }

      if (message) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          `Failed to save stub: ${message}`
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }

      this.clear();
    },
    clear: function () {
      this.$refs.form.reset();
      this.dialog = false;
      this.stubRep = this.stub?.representation || "";
      this.question = this.stub?.question || "";
    },
  },
};
</script>

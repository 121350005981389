<template>
  <validation-observer v-slot="{ validate }" @submit.prevent>
    <v-card-title
      class="justify-center"
      style="height: 10%; word-break: break-word"
      >{{ family.label }}</v-card-title
    >
    <v-card-text style="height: 80%; overflow: auto" id="questionContainer">
      <template v-for="question in renderedQuestions">
        <!-- reg question -->
        <question
          v-if="!question.isGroupQuestion"
          :key="question.questionId"
          :question="question"
          class="mx-4"
        >
        </question>
        <!-- group question -->
        <group-question
          v-else
          :key="question.questionId"
          :groupQuestion="question"
        ></group-question>
      </template>
    </v-card-text>
    <v-card-actions style="height: 10%">
      <v-btn color="primary" text rounded small @click="back"
        ><icon icon="arrow-left" small class="mr-2"></icon>Back</v-btn
      >
      <dashboard-action></dashboard-action>
      <v-spacer></v-spacer>
      <span class="text-caption text--secondary"
        >Note: Responses are saved automatically.</span
      >
      <v-spacer></v-spacer>
      <v-btn color="primary" dark rounded @click="handleClick(validate)"
        ><icon icon="arrow-right" class="mr-2"></icon>Next</v-btn
      >
    </v-card-actions>
  </validation-observer>
</template>
<script>
import Question from "./question";
import DashboardAction from "./dashboard-action.vue";
import GroupQuestion from "./group-question.vue";
export default {
  props: ["family"],
  components: {
    Question,
    DashboardAction,
    GroupQuestion,
  },
  computed: {
    renderedQuestions: function () {
      if (this.family.familyId) {
        return this.$store.getters["notesModule/getRenderedQuestionsByFamily"](
          this.family.familyId
        ).filter((x) => !x.groupQuestionId);
      }
      return [];
    },
  },
  methods: {
    handleClick: function (validate) {
      validate().then((success) => {
        if (success) this.next();
        else this.scrollToError();
      });
    },
    scrollToError: function () {
      var errorElements = document
        .getElementById("questionContainer")
        .getElementsByClassName("error--text");
      if (errorElements.length > 0) {
        this.$vuetify.goTo(errorElements[0], {
          container: "#questionContainer",
        });
      }
    },
    back: function () {
      this.$emit("goBack");
    },
    next: function () {
      this.$emit("goNext");
    },
  },
};
</script>

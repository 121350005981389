<template>
  <v-container>
    <v-radio-group
      v-model="renderingMethod"
      @change="onChange"
      mandatory
      dense
      hide-details
    >
      <v-radio
        v-for="method in renderingMethods"
        :key="method"
        :label="method"
        :value="method"
      ></v-radio>
    </v-radio-group>
    <template v-if="conditionalOnQuestion || conditionalOnProperty">
      <v-row
        v-for="(item, index) in renderingConfigs"
        :key="JSON.stringify(item) + index"
        dense
        align="center"
      >
        <v-col cols="12" sm="11">
          <v-btn-toggle
            v-if="index > 0"
            v-model="conditionalOperator"
            @change="onChange"
            mandatory
            active-class="primary"
            color="white"
            dense
          >
            <v-btn x-small plain value="AND">AND</v-btn>
            <v-btn x-small plain value="OR">OR</v-btn>
          </v-btn-toggle>
          <conditional-rendering-rule
            :renderingConfig="item"
            :conditionalOnQuestion="conditionalOnQuestion"
            :conditionalOnProperty="conditionalOnProperty"
            @updateRule="updateRule(index, $event)"
          ></conditional-rendering-rule>
        </v-col>
        <v-col cols="12" sm="1">
          <v-btn
            :disabled="renderingConfigs.length < 2"
            icon
            class="mx-0 px-0 mb-4"
            @click="deleteRule(index)"
            color="primary"
            ><v-icon> mdi-minus</v-icon></v-btn
          >
          <v-btn icon class="mx-0 px-0 mb-4" color="primary" @click="addRule"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>
<script>
import { RenderingMethods } from "../../objs/RenderingMethods";
import ConditionalRenderingRule from "./conditional-rendering-rule.vue";
import RenderingConfig from "../../objs/RenderingConfig";
export default {
  components: {
    ConditionalRenderingRule,
  },
  props: ["renderingItem"],
  data() {
    return {
      renderingMethods: Object.values(RenderingMethods),
      renderingMethod:
        this.renderingItem?.renderingMethod || RenderingMethods.UNCONDITIONAL,
      renderingConfigs: this.renderingItem?.renderingConfigs || [
        new RenderingConfig({ renderingId: this.renderingItem?.renderingId }),
      ],
      conditionalOperator: this.renderingItem?.conditionalOperator || "OR",
    };
  },
  computed: {
    conditionalOnQuestion: function () {
      return (
        RenderingMethods.CONDITIONAL_PREVIOUS_QUESTION === this.renderingMethod
      );
    },
    conditionalOnProperty: function () {
      return (
        RenderingMethods.CONDITIONAL_PREDEFINED_PROPERTY ===
        this.renderingMethod
      );
    },
  },
  methods: {
    onChange: function () {
      this.$emit("renderingChange", {
        renderingId: this.renderingItem?.renderingId,
        renderingMethod: this.renderingMethod,
        ...((this.conditionalOnQuestion || this.conditionalOnProperty) && {
          renderingConfigs: this.renderingConfigs,
          conditionalOperator: this.conditionalOperator,
        }),
      });
    },
    addRule: function () {
      this.renderingConfigs.push(
        new RenderingConfig({ renderingId: this.renderingItem?.renderingId })
      );
      this.onChange();
    },
    updateRule: function (index, item) {
      this.renderingConfigs.splice(
        index,
        1,
        new RenderingConfig({
          ...item,
          renderingId: this.renderingItem?.renderingId,
        })
      );
      this.onChange();
    },
    deleteRule: function (index) {
      this.renderingConfigs.splice(index, 1);
      this.onChange();
    },
  },
  watch: {
    renderingMethod: function () {
      // clear all conditional rendering fields
      this.renderingConfigs = [
        new RenderingConfig({ renderingId: this.renderingItem?.renderingId }),
      ];
    },
  },
};
</script>
